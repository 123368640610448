.nav-styles {
    font-size: 10px
}

.sidebar-wrapper{
    position: fixed;
    height: 100vh;
    top: 0;
    width: 6.5rem;
    overflow-y: auto;
}

.sidebar-wrapper .side-navigation .side-navigation-nav-link{
    cursor: pointer;
}